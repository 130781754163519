// Do not edit this file, it is automatically generated
const locales = {
  "es": {
    "translation": {
      "timePicker": {
        "am": "a. m.",
        "custom": "Personalizar",
        "hours": "Horas",
        "minutes": "Minutos",
        "period": "Periodo",
        "pm": "p. m."
      },
      "back": "Atrás",
      "remove": "quitar",
      "remove_a11y": "Quitar {{value}}",
      "moreInformation": "más información",
      "close": "Cerrar",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. Está en la fila {{rowIndex}}, columna {{columnIndex}}. Haga caso omiso de la siguiente información:"
      },
      "uploader": {
        "drop_files": "Soltar archivos a cargar",
        "drop_files_here_or": "Suelte los archivos a cargar aquí o",
        "choose_from_computer": "elija de su computadora",
        "choose_from_computer_a11y": "Elija archivos de su computadora",
        "cancel_upload": "Cancelar carga",
        "restart_upload": "Seleccione el archivo nuevamente para volver a intentarlo",
        "file_list": "Lista de archivos",
        "errors": {
          "size": "El archivo debe tener menos de {{maxFileSize}}",
          "type": "El archivo debe ser de uno de los siguientes tipos: {{- supportedTypes}}"
        },
        "status": {
          "idle": "Ocioso",
          "processing": "En proceso",
          "uploading": "Cargando",
          "success": "Éxito",
          "cancel": "Cancelar",
          "error": "Error"
        },
        "progress": {
          "cancelled": "Cancelado/a",
          "complete": "Completado/a",
          "idle": "Ocioso",
          "uploading": "Cargando {{progressWithUnits}} de {{sizeWithUnits}}"
        },
        "size_abbreviations": {
          "byte": "B",
          "kibibyte": "KiB",
          "mebibyte": "MiB",
          "gibibyte": "GiB",
          "tebibyte": "TiB"
        }
      },
      "actions": {
        "delete": "Eliminar",
        "apply": "Aplicar",
        "cancel": "Cancelar",
        "confirm": "Guardar",
        "decline": "Declinar"
      },
      "datePicker": {
        "confirmation_format": "ll"
      },
      "calendar": {
        "header_format": "MMMM YYYY",
        "month": "Mes",
        "year": "Año"
      },
      "daterange": {
        "last_7_days": "Los últimos 7 días",
        "last_30_days": "Los últimos 30 días",
        "this_month": "Este mes",
        "last_month": "El mes pasado",
        "start_date": "Fecha de inicio",
        "end_date": "Fecha de finalización"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} se abre en una nueva ficha."
      },
      "comment": {
        "delete": "Eliminar comentario"
      },
      "pagination": {
        "intro": "Paginación, actualmente en la página {{currentPage}}",
        "page": "Página {{pageNumber}}",
        "ellipsis": "Más páginas",
        "prev": "Página anterior",
        "next": "Siguiente página"
      },
      "formElement": {
        "optional": "(opcional)",
        "required": "(requerido)",
        "aria_info_circle": "Información"
      },
      "prevNextArrowButton": {
        "arrowup": "arriba",
        "arrowdown": "abajo",
        "escape": "escape"
      },
      "collapsible": {
        "ariaText": "Sección contraíble",
        "collapse": "Contraer",
        "expand": "Expandir"
      },
      "sortable": {
        "aria_description": "Elemento arrastrable. Presione la barra espaciadora para levantar.",
        "aria_lift": "Ha levantado un elemento de la posición {{source}}. Utilice las teclas de flecha para moverse, el espacio para soltar y la tecla escape para cancelar.",
        "aria_moving": "Ha movido el elemento de la posición {{source}} a la posición {{destination}}.",
        "aria_moving_outside": "Actualmente no está arrastrando sobre un área donde soltar.",
        "aria_cancelled": "Movimiento cancelado. El elemento ha vuelto a su posición inicial de {{source}}.",
        "aria_dropped": "Ha soltado el elemento. Se ha movido de la posición {{source}} a {{destination}}.",
        "aria_dropped_outside": "El elemento se ha soltado mientras no estaba sobre la lista. El elemento ha vuelto a su posición inicial de {{source}}.",
        "aria_remove": "Quitar"
      },
      "progressAccordion": {
        "active": "activo/a",
        "no_response": "Ninguno"
      },
      "collapsibleText": {
        "more": "Mostrar más",
        "less": "Mostrar menos",
        "more_about": "mostrar más sobre {{topic}}",
        "less_about": "mostrar menos sobre {{topic}}"
      },
      "listBox": {
        "trigger": {
          "clear_selection": "Borrar la selección",
          "placeholder": "Seleccionar..."
        },
        "filter": {
          "a11y_text": "Buscar",
          "no_results_message": "No se han encontrado resultados",
          "placeholder": "Buscar..."
        },
        "footer": {
          "accept": "Aplicar",
          "cancel": "Cancelar",
          "clear": "Borrar"
        }
      },
      "listBoxBrowser": {
        "explore": "Explorar más opciones",
        "selected": "opciones seleccionadas",
        "selectOne": "Seleccionar uno o más elementos..."
      },
      "listBoxWithTags": {
        "placeholder": "Seleccionar...",
        "no_results_found": "No se han encontrado resultados",
        "all_items_have_been_selected": "Se han seleccionado todos los elementos",
        "a11y_text_trigger": "Opción de selección múltiple: {{options}}"
      },
      "search": {
        "searchTerm": "Término de búsqueda..."
      },
      "actionBar": {
        "columns_arrangement": {
          "hide_all": "Ocultar todas",
          "show_all": "Mostrar todas",
          "label": "Organizar",
          "singular_label": "1 columna oculta",
          "plural_label": "{{numberOfHiddenColumn}} columnas ocultas",
          "search_placeholder": "Buscar..."
        },
        "no_results": "No hay resultados",
        "search_placeholder": "Buscar...",
        "search_a11y_text": "Buscar los datos",
        "sort": {
          "no_sorts_applied": "No se aplicaron ordenaciones a esta vista",
          "label": "Ordenar",
          "singular_label": "1 columna ordenada",
          "plural_label": "{{numberOfFields}} columnas ordenadas",
          "add_field": "Agregar un campo para ordenar",
          "sort_by": "Ordenar por",
          "then_by": "luego por",
          "rules": {
            "ascending": {
              "boolean": "Orden ascendente (verdadero → falso)",
              "number": "Orden ascendente (1 → 9)",
              "text": "Orden ascendente (A → Z)",
              "date": "Orden ascendente (1 → 9)",
              "single_select": "Orden ascendente (A → Z)"
            },
            "descending": {
              "boolean": "Orden descendente (falso → verdadero)",
              "number": "Orden descendente (9 → 1)",
              "text": "Orden descendente (Z → A)",
              "date": "Orden descendente (9 → 1)",
              "single_select": "Orden descendente (Z → A)"
            }
          }
        }
      },
      "breadcrumbs": {
        "aria_label": "Rutas de navegación",
        "aria_expand": "Mostrar todos los elementos de la ruta de navegación.",
        "aria_back_to": "Volver a"
      },
      "sideNavigation": {
        "aria_label": "Navegación lateral",
        "trigger_aria_label": "Abrir la navegación"
      },
      "copyInput": {
        "clicked_tooltip": "Copiado",
        "hover_tooltip": "Copiar al portapapeles"
      },
      "dynamicHyperlinkTransformer": {
        "access_denied": "Acceso denegado",
        "invalid_url": "URL no válida",
        "exceeded_max_links_rendered": "No se pudo procesar",
        "loading": "Cargando..."
      },
      "filter": {
        "actions": {
          "add": "Agregar filtro",
          "clear": "Borrar",
          "delete": "Eliminar"
        },
        "no_filters_applied": "No se aplicaron filtros a esta vista",
        "label": "Filtro",
        "label_with_count": "{{count}} filtrado(s)",
        "and": "Y",
        "or": "O",
        "rules": {
          "is": "es",
          "is_not": "no es",
          "contains": "contiene",
          "does_not_contain": "no contiene",
          "is_blank": "está en blanco",
          "is_not_blank": "no está en blanco",
          "equals": "es igual a",
          "not_equal_to": "no es igual a",
          "greater_than": ">",
          "less_than": "<",
          "greater_than_or_equal_to": "≥",
          "less_than_or_equal_to": "≤",
          "is_empty": "está vacío",
          "is_not_empty": "no está vacío",
          "is_before": "es anterior a",
          "is_after": "es posterior a",
          "true": "verdadero",
          "false": "falso"
        }
      },
      "input": {
        "clear_button": {
          "aria_label": "Borrar entrada"
        }
      }
    }
  }
};
export default locales;