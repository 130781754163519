// Do not edit this file, it is automatically generated
const locales = {
  "en": {
    "translation": {
      "timePicker": {
        "am": "am",
        "custom": "Custom",
        "hours": "Hours",
        "minutes": "Minutes",
        "period": "Period",
        "pm": "pm"
      },
      "back": "Back",
      "remove": "remove",
      "remove_a11y": "Remove {{value}}",
      "moreInformation": "more information",
      "close": "Close",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. You are on row {{rowIndex}}, column {{columnIndex}}. Disregard the following information:"
      },
      "uploader": {
        "drop_files": "Drop files to upload",
        "drop_files_here_or": "Drop files to upload here or",
        "choose_from_computer": "choose from your computer",
        "choose_from_computer_a11y": "Choose files from your computer",
        "cancel_upload": "Cancel upload",
        "restart_upload": "Select the file again to retry",
        "file_list": "List of files",
        "errors": {
          "size": "File must be smaller than {{maxFileSize}}",
          "type": "File must be one of the following types: {{- supportedTypes}}"
        },
        "status": {
          "idle": "Idle",
          "processing": "Processing",
          "uploading": "Uploading",
          "success": "Success",
          "cancel": "Cancel",
          "error": "Error"
        },
        "progress": {
          "cancelled": "Cancelled",
          "complete": "Complete",
          "idle": "Idle",
          "uploading": "Uploading {{progressWithUnits}} of {{sizeWithUnits}}"
        },
        "size_abbreviations": {
          "byte": "B",
          "kibibyte": "KiB",
          "mebibyte": "MiB",
          "gibibyte": "GiB",
          "tebibyte": "TiB"
        }
      },
      "actions": {
        "delete": "Delete",
        "apply": "Apply",
        "cancel": "Cancel",
        "confirm": "Save",
        "decline": "Decline"
      },
      "datePicker": {
        "confirmation_format": "MMMM DD, YYYY"
      },
      "calendar": {
        "header_format": "MMMM YYYY",
        "month": "Month",
        "year": "Year"
      },
      "daterange": {
        "last_7_days": "Last 7 Days",
        "last_30_days": "Last 30 Days",
        "this_month": "This Month",
        "last_month": "Last Month",
        "start_date": "Start Date",
        "end_date": "End Date"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} opens in a new tab."
      },
      "comment": {
        "delete": "Delete comment"
      },
      "pagination": {
        "intro": "Pagination, currently on page {{currentPage}}",
        "page": "Page {{pageNumber}}",
        "ellipsis": "More pages",
        "prev": "Previous page",
        "next": "Next page"
      },
      "formElement": {
        "optional": "(optional)",
        "required": "(required)",
        "aria_info_circle": "Information"
      },
      "prevNextArrowButton": {
        "arrowup": "up",
        "arrowdown": "down",
        "escape": "escape"
      },
      "collapsible": {
        "ariaText": "Collapsible section",
        "collapse": "Collapse",
        "expand": "Expand"
      },
      "sortable": {
        "aria_description": "Draggable item. Press space to lift.",
        "aria_lift": "You have lifted an item in position {{source}}. Use the arrow keys to move, space to drop, and escape to cancel.",
        "aria_moving": "You have moved the item from position {{source}} to position {{destination}}.",
        "aria_moving_outside": "You are currently not dragging over a droppable area.",
        "aria_cancelled": "Movement cancelled. The item has returned to its starting position of {{source}}.",
        "aria_dropped": "You have dropped the item. It has moved from position {{source}} to {{destination}}.",
        "aria_dropped_outside": "The item has been dropped while not over the list. The item has returned to its starting position of {{source}}.",
        "aria_remove": "Remove"
      },
      "progressAccordion": {
        "active": "active",
        "no_response": "None"
      },
      "collapsibleText": {
        "more": "Show more",
        "less": "Show less",
        "more_about": "show more about {{topic}}",
        "less_about": "show less about {{topic}}"
      },
      "listBox": {
        "trigger": {
          "clear_selection": "Clear selection",
          "placeholder": "Select..."
        },
        "filter": {
          "a11y_text": "Search",
          "no_results_message": "No results found",
          "placeholder": "Search..."
        },
        "footer": {
          "accept": "Apply",
          "cancel": "Cancel",
          "clear": "Clear"
        }
      },
      "listBoxBrowser": {
        "explore": "Explore more options",
        "selected": "selected options",
        "selectOne": "Select one or more items..."
      },
      "listBoxWithTags": {
        "placeholder": "Select…",
        "no_results_found": "No results found",
        "all_items_have_been_selected": "All items have been selected",
        "a11y_text_trigger": "Multi-select option: {{options}}"
      },
      "search": {
        "searchTerm": "Search term..."
      },
      "actionBar": {
        "columns_arrangement": {
          "hide_all": "Hide all",
          "show_all": "Show all",
          "label": "Arrange",
          "singular_label": "1 column hidden",
          "plural_label": "{{numberOfHiddenColumn}} columns hidden",
          "search_placeholder": "Search..."
        },
        "no_results": "No results",
        "search_placeholder": "Search...",
        "search_a11y_text": "Search the data",
        "sort": {
          "no_sorts_applied": "No sorts applied to this view",
          "label": "Sort",
          "singular_label": "1 column sorted",
          "plural_label": "{{numberOfFields}} columns sorted",
          "add_field": "Add a field to sort by",
          "sort_by": "Sort by",
          "then_by": "then by",
          "rules": {
            "ascending": {
              "boolean": "Sort ascending (true → false)",
              "number": "Sort ascending (1 → 9)",
              "text": "Sort ascending (A → Z)",
              "date": "Sort ascending (1 → 9)",
              "single_select": "Sort ascending (A → Z)"
            },
            "descending": {
              "boolean": "Sort descending (false → true)",
              "number": "Sort descending (9 → 1)",
              "text": "Sort descending (Z → A)",
              "date": "Sort descending (9 → 1)",
              "single_select": "Sort descending (Z → A)"
            }
          }
        }
      },
      "breadcrumbs": {
        "aria_label": "Breadcrumbs",
        "aria_expand": "Show all breadcrumb items.",
        "aria_back_to": "Back to"
      },
      "sideNavigation": {
        "aria_label": "Side navigation",
        "trigger_aria_label": "Open navigation"
      },
      "copyInput": {
        "clicked_tooltip": "Copied",
        "hover_tooltip": "Copy to clipboard"
      },
      "dynamicHyperlinkTransformer": {
        "access_denied": "Access denied",
        "invalid_url": "Invalid URL",
        "exceeded_max_links_rendered": "Could not render",
        "loading": "Loading..."
      },
      "filter": {
        "actions": {
          "add": "Add filter",
          "clear": "Clear",
          "delete": "Delete"
        },
        "no_filters_applied": "No filters applied to this view",
        "label": "Filter",
        "label_with_count": "{{count}} filtered",
        "and": "And",
        "or": "Or",
        "rules": {
          "is": "is",
          "is_not": "is not",
          "contains": "contains",
          "does_not_contain": "does not contain",
          "is_blank": "is blank",
          "is_not_blank": "is not blank",
          "equals": "equals",
          "not_equal_to": "not equal to",
          "greater_than": ">",
          "less_than": "<",
          "greater_than_or_equal_to": "≥",
          "less_than_or_equal_to": "≤",
          "is_empty": "is empty",
          "is_not_empty": "is not empty",
          "is_before": "is before",
          "is_after": "is after",
          "true": "true",
          "false": "false"
        }
      },
      "input": {
        "clear_button": {
          "aria_label": "Clear input"
        }
      }
    }
  }
};
export default locales;